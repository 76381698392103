import HTTP from './AppConfig';

export default {
   list({ paginacion = 20, pagina = 1, search = '' }) {
      return HTTP.send(
         'GET',
         '/usuario-lista?paginate=' + paginacion + '&page=' + pagina + '&search=' + search
      );
   },
   create({ form }) {
      return HTTP.sendForm('POST', '/usuario-crear', form);
   },
   update({ form }) {
      return HTTP.sendForm('POST', '/usuario-actualizar', form);
   },
   delete({ id_usuario }) {
      return HTTP.send('GET', '/usuario-eliminar?id_usuario=' + id_usuario);
   },
   updatePassword({ form }) {
      return HTTP.sendForm('POST', '/usuario-actualizar-password', form);
   },
   incomeControl() {
      return HTTP.sendForm('POST', '/income-control');
   },
   incomeControlDashboard({parameters}) {
      return HTTP.send('GET', '/income-control-dashboard', parameters);
   },
};
