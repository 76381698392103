import { createRouter, createWebHashHistory } from 'vue-router';
import GLOBAL from '@/utils/global.js';

const LOG = 'router.js';
const routes = [
   {
      path: '/login',
      name: 'Login',
      component: () => import('../views/Login'),
      meta: { authRequired: 'false' },
   },
   {
      path: '/registro',
      name: 'Registro',
      component: () => import('../views/Registro'),
      meta: { authRequired: 'false' },
   },
   {
      path: '/reset-password',
      name: 'ResetPassword',
      component: () => import('../views/ResetPassword'),
      meta: { authRequired: 'false' },
   },
   {
      path: '/recover-password/:code',
      name: 'RecoverPassword',
      component: () => import('../views/RecoverPassword'),
      meta: { authRequired: 'false' },
   },
   {
      path: '/test',
      name: 'Test',
      component: () => import('../views/test.vue'),
      meta: { authRequired: 'false' },
   },
   
   {
      path: '/',
      name: 'Mapa',
      component: () => import('../views/Mapa/index'),
      meta: { authRequired: 'false' },
   },
   //paginas extras
   {
      //pagina no autorizada
      path: '/not-authorized',
      name: 'not-authorized',
      props: true,
      component: () => import('../views/pages-states/NotAuthorized.vue'),
      meta: { authRequired: 'false' },
   },
   {
      //pagina no encontrada
      path: '/:pathMatch(.*)',
      component: () => import('../views/pages-states/404.vue'),
      meta: { authRequired: 'false' },
   },
];

const router = createRouter({
   history: createWebHashHistory(),
   routes,
});

router.beforeEach((to, from, next) => {
   // preguntamos si la ruta existe
   if (to.name === undefined) {
      next({ name: 'nofound' });
   } else {
      let estado = to.meta.authRequired;
      // preguntamos si la pagina requiere autenticacion
      if (estado === 'true') {
         GLOBAL.log(LOG + ':Pide Autentificacion ' + estado);
         // preguntamos si existe una sesion
         if (localStorage.getItem('user')) {
            //muestra la pagina deseada
            next();
            GLOBAL.log(LOG + ':Muestra pagina');
         } else {
            next({ name: 'Login' });
            GLOBAL.log(LOG + ':Muestra login');
         }
      } else {
         //Preguntamso si esta autentificado y si la pagina es login
         if ((to.name === 'Login' || to.name === 'Registro') && localStorage.getItem('user')) {
            next({ name: 'Home' });
            GLOBAL.log(LOG + ':Muestra inicio');
         } else {
            //procedemos a mostrar la pagina
            next();
            GLOBAL.log(LOG + ':Muestra pagina no auth');
         }
      }
   }
});

export default router;
