import AuthService from '../services/auth.service';
//recuperamos el usuario actual
const user = JSON.parse(localStorage.getItem('user'));
//verificamos si existe o no un usuario
const initialState = user
   ? { status: { loggedIn: true }, user }
   : { status: { loggedIn: false }, user: null };

export const auth = {
   namespaced: true,
   state: initialState,
   actions: {
      login({ commit }, user) {
         return AuthService.login(user).then(
            (user) => {
               commit('loginSuccess', user);
               return Promise.resolve(user);
            },
            (error) => {
               commit('loginFailure');
               return Promise.reject(error);
            }
         );
      },
      register({ commit }, user) {
         return AuthService.register(user).then(
            (user) => {
               commit('loginSuccess', user);
               return Promise.resolve(user);
            },
            (error) => {
               commit('loginFailure');
               return Promise.reject(error);
            }
         );
      },
      logout({ commit }) {
         AuthService.logout();
         commit('logout');
      },
   },
   mutations: {
      loginSuccess(state, user) {
         state.status.loggedIn = true;
         state.user = user;
      },
      loginFailure(state) {
         state.status.loggedIn = false;
         state.user = null;
      },
      logout(state) {
         state.status.loggedIn = false;
         state.user = null;
      },
      registerSuccess(state) {
         state.status.loggedIn = false;
      },
      registerFailure(state) {
         state.status.loggedIn = false;
      },
   },
};
