<template>
  <router-view />
</template>

<script>
import UsuariosService from "@/services/usuarios.service";

export default{
  mounted() {
    let me=this;
    UsuariosService.incomeControl()
        .then((response) => {
          // console.log(response);
          me.GLOBAL.log(response);
        })
        .catch(function (err) {
          me.GLOBAL.log(err);
        });
  }
}
</script>


<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
