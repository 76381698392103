import axios from 'axios';
import GLOBAL from '@/utils/global.js';

//recuperamos la url del api segun el tipo de desarrollo
const API_URL = GLOBAL.baseUrlApi();

class AuthService {
   login(user) {
      return axios
         .post(API_URL + '/login', {
            user: user.user,
            password: user.password,
         })
         .then((response) => {
            if (response.data.token) {
               localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
         });
   }
   register(user) {
      return axios
         .post(API_URL + '/register', {
            email: user.user,
            password: user.password,
            id_ciudad: user.id_ciudad,
         })
         .then((response) => {
            if (response.data.token) {
               localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
         });
   }

   logout() {
      localStorage.removeItem('user');
      localStorage.removeItem('welcome.status');
   }
}
export default new AuthService();
