/*eslint no-useless-escape: "error"*/
export default {
   isThereALogin() {
      return;
   },
   baseUrlApi() {
      // let url = './public/assets/data-config.json';
      // if (process.env.VUE_APP_NODE_ENV == 'local') {
      //    url = './data-config.json';
      // }
      // const jsonData = require(url);
      // console.log(jsonData);

      // let url_api_produccion = '';
      // fetch(url)
      //    .then((response) => {
      //       return response.json();
      //    })
      //    .then((jsondata) => {
      //       url_api_produccion = jsondata.url_api;
      return process.env.VUE_APP_NODE_ENV == 'local'
         ? process.env.VUE_APP_BASE_API_LOCAL
         : JS_URL_API;
      // : process.env.VUE_APP_BASE_API_PRODUCTION;
      // });
   },
   baseUrl(path = '') {
      return process.env.VUE_APP_NODE_ENV == 'local'
         ? process.env.VUE_APP_BASE_URL_LOCAL + path
         : JS_URL_BASE + path;
      // let url = './assets/data-config.json';
      // if (process.env.VUE_APP_NODE_ENV == 'local') {
      //    url = './data-config.json';
      // }
      // // const jsonData = require(url);
      // // console.log(jsonData);

      // let url_base_produccion = '';
      // fetch(url)
      //    .then((response) => {
      //       return response.json();
      //    })
      //    .then((jsondata) => {
      //       url_base_produccion = jsondata.url_base;
      //       return process.env.VUE_APP_NODE_ENV == 'local'
      //          ? process.env.VUE_APP_BASE_URL_LOCAL + path
      //          : url_base_produccion;
      //       //: process.env.VUE_APP_BASE_URL_PRODUCTION + path;
      //    });
   },
   log(text) {
      if (process.env.VUE_APP_NODE_ENV == 'local') {
         console.log(text);
      }
   },
   generateUUID() {
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
         var r = (dt + Math.random() * 16) % 16 | 0;
         dt = Math.floor(dt / 16);
         return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
      });
      return uuid;
   },
   isItAnEmail(valor) {
      if (
         /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            valor
         )
      ) {
         return true;
      } else {
         return false;
      }
   },
   isItANumber(valor) {
      if (valor.match(/^[0-9]+$/)) {
         return true;
      } else {
         return false;
      }
   },
   isItANumberDecimal(valor) {
      let valoresAceptados = /^[0-9]+$/;
      if (valor.indexOf('.') === -1) {
         if (valor.match(valoresAceptados)) {
            return true;
         } else {
            return false;
         }
      } else {
         //dividir la expresión por el punto en un array
         var particion = valor.split('.');
         //evaluamos la primera parte de la división (parte entera)
         if (particion[0].match(valoresAceptados) || particion[0] == '') {
            if (particion[1].match(valoresAceptados)) {
               return true;
            } else {
               return false;
            }
         } else {
            return false;
         }
      }
   },
   random(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
   },
   roundNumber(numb, decimal) {
      return numb.toFixed(decimal);
   },
   textNoEmpty(value) {
      return value == null || value == 'null' || value.length <= 0 ? '---' : value;
   },
   existConnetion() {
      var cadena = 'http://google.com';

      var request = new XMLHttpRequest();
      return request.open('GET', cadena, false);

      if (request.status == '200') {
         return true;
      }
      return false;
   },
   getDateSystem() {
      var date = new Date();
      return (
         date.getFullYear() +
         '-' +
         (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
         '-' +
         (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
      );
   },
   generateUUID() {
      //obtenemos microtime
      var now = (Date.now ? Date.now() : new Date().getTime()) / 1000;

      //obtenemos numero aletarorio
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result1 = '';
      const charactersLength = characters.length;
      for (let i = 0; i < 5; i++) {
         result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      //obtenemos fecha actual
      var date = new Date();
      var fecha_actual =
         date.getFullYear() +
         '' +
         (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
         '' +
         (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      return fecha_actual + Math.floor(now) + result1;
   },
   generateUUIDv2() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
         (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
         var r = Math.random() * 16; //random number between 0 and 16
         if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
         } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
         }
         return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      });
   },
   isObjEmpty(obj) {
      //validar si un objeto esta vacio
      for (var prop in obj) {
         if (obj.hasOwnProperty(prop)) return false;
      }
      return true;
   },
   countObject(obj) {
      let lengthOfObject = Object.keys(obj).length;
      return lengthOfObject;
   },
   isKeyExists(obj, key) {
      if (obj[key] == undefined) {
         return false;
      } else {
         return true;
      }
   },
   getErrorHTTP(error) {
      var itemsError = '';
      if (error.response.status == 400) {
         error.response.data.forEach((element) => {
            itemsError += element + '<br>';
         });
      } else {
         itemsError =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
      }
      return itemsError;
   },
};
