import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import global_ from './utils/global';

const app = createApp(App);
app.config.globalProperties.GLOBAL = global_;
app.config.globalProperties.gpAuxIndice = async()=>{
    let url = "./public/assets/indice_colores.json";
    if (process.env.VUE_APP_NODE_ENV == "local") {
      url = "./indice_colores.json";
    }
    return await fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        global_.log(data);
       return data;
      });
};
app.use(store);
app.use(router);
app.mount('#app');
