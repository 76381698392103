// import Vue from 'vue'
import axios from 'axios';
import GLOBAL from '@/utils/global.js';

//configuramos la url base del api
const baseURL = GLOBAL.baseUrlApi();
let apiJson = axios.create({
   baseURL: baseURL,
});
// response interceptor
apiJson.interceptors.response.use(
   (response) => {
      //envia respuestas recibidas
      GLOBAL.log(response);
      return response;
   },
   (err) => {
      GLOBAL.log(err);
      if (err.response) {
         //verificamos que el token no haya caducado
         if (err.response.status == 401) {
         }
      } else if (err.request) {
         // client never received a response, or request never left
         alert('No hay respuesta del servidor: \n\n' + err.request);
      } else {
         // anything else
         alert('No hay conexion con el servidor: \n\n' + err);
      }
      //envia respuestas fallidas 500 o 400
      return Promise.reject(err);
   }
);

export default {
   sendForm(method, url, dataForm = {}) {
      const user = JSON.parse(localStorage.getItem('user'));
      return apiJson({
         url: url,
         method: method,
         data: dataForm,
         headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Content-Type': 'multipart/form-data',
            Authorization: user ? user.token : '',
         },
      });
   },
   send(method, url, dataForm = {}) {
      const user = JSON.parse(localStorage.getItem('user'));
      let sendData = {
         url: url,
         method: method,
         headers: {
            'Content-Type': 'application/json',
            Authorization: user ? user.token : '',
         },
      }
      if (sendData.method == "GET" || sendData.method == "get") {
         sendData["params"] = dataForm;
      } else {
         sendData["data"] = dataForm;
      }
      console.log(sendData);
      return apiJson(sendData);
   },
};
